<template>
    <div class="form-container">
        <div>
            <img src="@/assets/authen247.png" alt="Title" class="title-image"/>
        </div>
        <div class="header">
            <div v-if="header_data" class="verified_data">
                {{ header_data }}
            </div>
            <div v-if="isValidData" class="header-text"> You just confirm your buying of the following product</div>
        </div>

        <div class="card" v-if="isValidData">

            <div class="product-name">{{ product }}</div>

            <div v-if="product_image_url" class="product-image-container">
                <img :src="product_image_url" alt="Product Image" class="product-image">
            </div>

            <form>
                <div class="form-group">
                    <label for="lot">Product Lot:</label>
                    <input type="text" v-model="lot" id="lot" :readonly="true" />
                </div>
                <hr>
                <div class="form-group">
                    <label for="manufacturer">Manufacturer:</label>
                    <input type="text" v-model="manufacturer" id="manufacturer" :readonly="true" />
                </div>
                <hr>
                <div class="form-group">
                    <label for="expiry_date">Expired Date:</label>
                    <input type="text" v-model="expiry_date" id="expiry_date" :readonly="true" />
                </div>
            </form>
        </div>
        <div class="bottom"  v-if="isValidData">
                <button class="green-button" @click="redirectToLineAuth"> 
                    <img src="@/assets/line.png" alt="LINE" class="line-image"/> Collect Points on LINE
                </button>
            <div class="bottom-text"> This purchase earn you <strong style="color: #21334C;">{{ product_point }}+</strong> points</div>
        </div>
    </div>
</template>


<script>
import apiClient from '../services/api.js';
import CreateUserPopup from './CreateUserPopup.vue';

export default {
    name: 'ProductRegistration',
    components: {
        CreateUserPopup
    },
    data() {
        return {
            qrCode: null,
            product: null,
            product_image_url: null,
            product_point: null,
            lot: null,
            manufacturer: null,
            expiry_date: null,
            header_data: null,
            isValidData: false,
        };
    },
    async mounted() {
        this.qrCode = this.$route.params.qrCode || '';
        try {
            const response = await apiClient.get('purchase', {
                params: {
                    qr_code: this.qrCode,
                }
            });   

            this.product = response.data.product;
            this.product_image_url = response.data.product_image_url;
            this.product_point = response.data.product_point;
            this.lot = response.data.lot;
            this.manufacturer = response.data.manufacturer;
            this.expiry_date = response.data.expiry_date;
            this.header_data = response.data.data;
            this.isValidData = true;
        }  catch(error){
            this.header_data = error.response.data.msg;
            this.isValidData = false;
        } finally {
            
        }     
    },
    methods: {
        navigateTo(page, response_data) {
            const accumulated_point = response_data.product_credit_point;
            const total_accumulated_point = response_data.total_accumulated_point;
            this.$router.push({ 
                path: `/${page}`,
                query: { accumulated_point, total_accumulated_point }  
            });
        },
        redirectToLineAuth() {
            const baseURL = 'https://access.line.me/oauth2/v2.1/authorize';
            const queryParams = new URLSearchParams({
                response_type: 'code',
                client_id: process.env.VUE_APP_CLIENT_ID,
                redirect_uri: process.env.VUE_APP_REDIRECT_URI,
                state: `&state=12345abcde&qrCode=${this.qrCode}`,
                scope: 'profile openid',
                nonce: '09876xyz'
            });
            const lineAuthURL = `${baseURL}?${queryParams.toString()}`;
            window.location.href = lineAuthURL;
        },
    },
};
</script>

<style scoped>
    .form-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100vh;
      max-height: 100vh;
      overflow-y: auto;
      background-color: #E9EBED;
      padding: 20px;
      box-sizing: border-box;
    }

    .title-image{
        max-width: 35%;
        height: auto;
        border-radius: 4px;
    }

    .header {
        margin-bottom: 20px; 
        text-align: center;
        width: 90%;
        max-width: 300px;
    }

    .header-text {
        font-size: 11px;
        color: #7A8594;
    }

    .card {
      background: white;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      text-align: center;
      width: 300px;
    }

    .product-name {
        text-align: left; 
        font-weight: bold; 
        color: black; 
        margin-bottom: 50px;
        font-family: "Times New Roman", serif;
    }

    .product-image-container {
        margin-bottom: 20px;
        text-align: center;
        max-height: 280px;
        overflow: hidden;
    }

    .product-image {
        max-width: 80%;
        height: auto;
        border-radius: 4px;
        max-height: 280px;
        object-fit: cover;
    }

    .verified_data{
        color: #21334C;
        font-weight: bold;
        font-style: italic;
        font-family: "Times New Roman", serif;
        margin-bottom: 0;
        padding-bottom: 0;
        font-size: 20px;
    }

    .form-group {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
    }

    label {
        margin-right: 10px; 
        text-align: start;
        font-style: italic;
        width: 40%;
        color: rgb(142, 139, 139);
        font-family: "Times New Roman", serif;
        font-size: 15px;
    }

    input {
        width: 60%;
        padding: 8px;
        box-sizing: border-box;
        border: none !important;
        border-radius: 4px;
        outline: none;
        text-align: end;
        font-family: "Times New Roman", serif;
        font-size: 15px;
    }

    input:focus {
        outline: none; 
        box-shadow: none; 
    }

    .green-button {
        margin-top: 20px;
        margin-bottom: 10px;
        background-color: #00c300; 
        font-weight: 900;
        color: white; 
        padding: 10px;
        border: none;
        border-radius: 50px;
        font-size: 20px;
        cursor: pointer;
        width: 100%;
        display: flex; 
        align-items: center; 
        justify-content: center;
    }

    .green-button img {
        margin-right: 10px;
    }

    .green-button:hover {
        background-color: #009f00; 
    }

    .green-button:focus {
        outline: none;
    }

    .bottom {
        width: 340px;
    }

    .bottom-text {
        font-size: 15px;
        color: #7A8594;
    }

    .line-image {
        max-width: 10%;
        height: auto;
        border-radius: 4px;
        background-color: white;
        vertical-align: middle; 
    }

</style>
