<template>
    <div class="home-container">
        <div class="card">
            <h1>Main Menu</h1>
            <button @click="navigateTo('')">Product Registration</button>
            <button @click="navigateTo('purchase-history')" style="color:">Purchase History</button>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Home',
    methods: {
        navigateTo(page) {
            this.$router.push({ path: `/${page}` });
        },
    },
};
</script>

<style scoped>
.home-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.card {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

button {
  display: block;
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1;
  border-color: #169898;
  border-radius: 4px;
  background-color: #feffff;
  color: rgb(5, 5, 5);
  font-size: 16px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

</style>
